
import { defineComponent, ref, onUnmounted } from "vue";
// import { onBeforeRouteUpdate, useRoute } from 'vue-router'
// import type { RouteLocationNormalized } from 'vue-router'
import moment from "moment";
import { useI18n } from "vue-i18n";
import RouteTabs from "@/views/Checking/spotChecking/components/RouteTabs.vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex'
import useEventbus from "@/hooks/checking/spotChecking/useEventbus";
import { getDealerlist, getFinancialList, fetchCheckStatusList } from "@/API/checking/spotChecking";
export default defineComponent({
  name: "spotChecking",
  components: {
    RouteTabs,
  },
  setup() {
    const { customOn, customOff } = useEventbus();
    const total = ref<number>(0);
    customOn("on-count-change", (count: number) => {
      total.value = count;
    });
    onUnmounted(() => {
      customOff("on-count-change");
    });
    // 获取spot check confirm初始化数量
    const query = {
      entity: null,
      bu: null,
      priority: null,
      submissionPeriodEnd: "",
      submissionPeriodStart: "",
    };
    query.submissionPeriodStart = moment()
      .month(moment().month() - 1)
      .startOf("month")
      .format("YYYY-MM-DD");
    query.submissionPeriodEnd = moment()
      .month(moment().month() + 1)
      .endOf("month")
      .format("YYYY-MM-DD");
    const fetchDealerList = () => {
      return new Promise((resolve) => {
        getDealerlist(query).then((res: any) => {
          resolve(res.data ? res.data.length : 0);
        });
      });
    };
    const fetchFinancialList = () => {
      return new Promise((resolve) => {
        getFinancialList(query).then((res: any) => {
          resolve(res.data ? res.data.length : 0);
        });
      });
    };
    const dealer = fetchDealerList();
    const fl = fetchFinancialList();
    Promise.all([dealer, fl]).then((res: any) => {
      total.value = res[0] + res[1];
    });

    const { t } = useI18n();
    // spotChecking
    const routeName = ref("spotCheckingControlling");
    const defaultBreadCrumbData = [
      { name: t("checking.checking"), path: "" },
      { name: t("checking.spotCheckingControlling"), path: "" },
    ];
    const breadCrumbData = ref(defaultBreadCrumbData);

    // check status list存储  4722和4723使用
    const { commit } = useStore();
    const getStatusList = () => {
      fetchCheckStatusList().then((res: any) => {
        if (res) {
          commit("spotChecking/updateSpotCheckStatus", res);
        }
      });
    };
    getStatusList();
    return {
      breadCrumbData,
      routeName,
      total,
    };
  },
});
